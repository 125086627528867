<script setup lang="ts">
import { nanoid } from 'nanoid'
import type { IManagerFieldEmits, IManagerFieldProps } from './types'
import {
  hasFieldValues,
  type INodeFieldValue,
  useField,
  useFieldCache,
  useFieldCalculation,
  useFieldCast,
} from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
})
const emit = defineEmits<IManagerFieldEmits>()

const multiple = props.node.multiple ?? false
const values = computed(() =>
  hasFieldValues(props.node)
    ? props.node.values?.map<INodeFieldValue & { __id: string }>((val) => ({
        ...val,
        __id: nanoid(),
      }))
    : undefined,
)

const { modelValue, parentValue, handleChange, handleBlur } = useField(
  props,
  emit,
)

const { castPlugin } = useFieldCast(props.node)

// Initialize calculated field, after setting default value
const { registerCalculated } = useFieldCalculation(props.node, parentValue)
registerCalculated()

useFieldCache(props.node, parentValue)

const vModels = ref<Record<string, boolean>>(
  values.value?.reduce(
    (acc, val) => {
      acc[val.__id] = modelValue.value === val.value
      return acc
    },
    {} as Record<string, boolean>,
  ) ?? {},
)

const onChange = (
  event: Event,
  { __id, value }: INodeFieldValue & { __id: string },
) => {
  if (!values.value) return
  for (const val of values.value) {
    if (val.__id !== __id) {
      vModels.value[val.__id] = false
    }
  }

  const _value = (event.target!.checked as boolean) ? value : null

  modelValue.value = _value
  handleChange(_value)
}
</script>

<template>
  <ManagerField v-bind="props">
    <template #default="{ id, name, label }">
      <Tooltip
        class="bg-gray-950 text-xs"
        outer-class="grow flex"
        :content="node.tooltip ? node.tooltip : undefined"
        placement="top"
      >
        <!-- Multiple Checkbox --- Array Value -->
        <FormKit
          v-if="multiple"
          :id="id"
          v-model="modelValue"
          type="checkbox"
          :name="name"
          :label="label"
          :options="values"
          label-by="key"
          track-by="value"
          outer-class="w-full"
          label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
          :plugins="[castPlugin]"
          @input="handleChange"
          @blur="handleBlur"
        />

        <!-- Exclusive Checkbox --- Single Value -->
        <div v-else-if="values" class="flex gap-4">
          <FormKit
            v-for="value in values"
            :id="value.__id"
            :key="value.__id"
            v-model="vModels[value.__id]"
            type="checkbox"
            :name="name + '_' + value.__id"
            :label="value.key"
            label-by="key"
            track-by="value"
            outer-class="w-full"
            label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
            :plugins="[castPlugin]"
            @change="onChange($event, value)"
          />
        </div>

        <!-- Default Checkbox --- Boolean -->
        <FormKit
          v-else
          v-model="modelValue"
          type="checkbox"
          :name="name"
          :label="label"
          label-by="key"
          track-by="value"
          outer-class="w-full"
          label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
          :plugins="[castPlugin]"
          @input="handleChange"
          @blur="handleBlur"
        />
      </Tooltip>
    </template>
  </ManagerField>
</template>
